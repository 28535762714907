<!-- @format -->

<script setup>
import SideBar from "@/components/SideBar.vue";
import HTMLCode from "@/components/HTMLCode.vue";
import CodeCompletion from "@/components/CodeCompletion.vue";
import Demo from "@/components/Demo.vue";
import CodeGenerate from "@/components/CodeGenerate.vue";
import options from "@/data/data";
import { ref, watch } from "vue";
import AssignmentForm from "@/components/AssignmentForm.vue";
const optionId = ref(0);
const showIDE = ref(false);
const showAssignment = ref(false);
const showCodeCompletion = ref(false);
const showFeedbackForm = ref(false);
const taskType = ref("");
const isOpen = ref(true);
const code = ref(options[optionId.value].htmlCode);
const codeFromBox = ref("");
const desc = ref("Plug and play JDoodle IDE in your application.");
const codeIDE = ref(`//
// This is a sample code
//
public static int fibonacci(int n) {
    if (n <= 1) {
        return n;
    } else {
        return fibonacci(n - 1) + fibonacci(n - 2);
    }
}
`);
const descIDE = ref("AI - Code Generation");

watch(
  () => optionId.value,
  (newOption, oldOption) => {
    code.value = options[optionId.value].htmlCode;
    codeFromBox.value = options[optionId.value].htmlCode;
    desc.value = options[optionId.value].desc;
  }
);

const updateOptionId = (selectedOption) => {
  showAssignment.value = false;
  showIDE.value = false;
  showFeedbackForm.value = false;

  optionId.value = selectedOption;
};

const onShowAssignment = (flag) => {
  showIDE.value = false;
  showCodeCompletion.value = false;
  showFeedbackForm.value = false;

  showAssignment.value = flag;
};
const onShowIde = (flag) => {
  showAssignment.value = false;
  showCodeCompletion.value = false;
  showFeedbackForm.value = false;
  showIDE.value = flag;
};

const onShowFeedbackForm = (flag) => {
  showIDE.value = false;

  showAssignment.value = false;
  showFeedbackForm.value = flag;
  showCodeCompletion.value = false;
};

const onShowCodeCompletion = (flag, task) => {
  showCodeCompletion.value = flag;
  taskType.value = task;
  showIDE.value = false;

  showAssignment.value = false;
  showFeedbackForm.value = false;
};

const onCodeChange = (code) => {
  codeFromBox.value = code;
};
</script>

<template>
  <div class="bg-orange-400 flex lg:flex-grow sticky top-0 z-20 h-[64px]">
    <button @click="isOpen = !isOpen" class="mb-auto top-0 right-0 px-4 py-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-8 h-8"
      >
        <path
          fill-rule="evenodd"
          d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <div class="flex justify-between">
      <a
        class="inline-flex items-center gap-x-2 text-xl font-semibold"
        href="#"
      >
        <img
          class="w-10 h-auto rounded-full"
          src="../assets/logo.png"
          alt="Logo"
        />
        JDoodle - Demo
      </a>
    </div>
    <!-- <div class="flex justify-center">Jdoodle - Demo Portal</div> -->
  </div>
  <div class="bg-gray-50 flex gap-2 md:gap-8 md:h-[100vh-64px] h-full relative">
    <div class="flex justify-start z-10 top-0 bottom-0 absolute sm:relative">
      <SideBar
        @selectOption="updateOptionId"
        @showIde="onShowIde"
        @showAssignment="onShowAssignment"
        @showFeedbackForm="onShowFeedbackForm"
        @showCodeCompletion="onShowCodeCompletion"
        :isOpen="isOpen"
      />
    </div>
    <div class="h-full p-6 md:p-10 w-full">
      <div class="grid md:grid-cols-2">
        <div
          id="HTML"
          v-if="
            !showIDE &&
            !showAssignment &&
            !showFeedbackForm &&
            !showCodeCompletion
          "
          class="h-full mx-4"
        >
          <HTMLCode :code="code" @updateIframeContent="onCodeChange" />
        </div>
        <div id="DEMO">
          <Demo
            v-if="
              !showIDE &&
              !showAssignment &&
              !showFeedbackForm &&
              !showCodeCompletion
            "
            :code="code"
            :code-from-box="codeFromBox"
            :desc="desc"
          />
        </div>
      </div>

      <div
        v-if="showIDE && !showAssignment && !showCodeCompletion"
        class="pl-6 max-w-4xl min-w-min h-full"
      >
        <CodeGenerate :codeIDE="codeIDE" :descIDE="descIDE" />
      </div>
      <div
        v-if="showCodeCompletion && !showAssignment && !showIDE"
        class="pl-6 flex-1 max-w-4xl min-w-min"
      >
        <CodeCompletion :taskType="taskType" />
      </div>
      <div
        v-if="showAssignment && !showIDE && !showCodeCompletion"
        class="px-2 max-w-4xl"
      >
        <AssignmentForm />
      </div>
      <div
        v-if="showFeedbackForm && !showIDE && !showAssignment"
        class="w-[80%] h-[80%] mt-1rem"
      >
        <iframe
          class="border-3 rounded-md border-gray-400"
          frameborder="0"
          style="height: 700px; width: 100%; border: none"
          src="https://forms.zohopublic.com.au/hello59/form/JDoodleFeedbackForm/formperma/KU_0mr7eZFmafCR-iUL5-YWED5Xqj7bKDtsVhbldEus"
        ></iframe>
      </div>
    </div>
  </div>
</template>
