<!-- @format -->

<script setup>
import { ref, watch } from "vue";
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import { EditorView } from "codemirror";
import MarkdownIt from "markdown-it";

const markdown = new MarkdownIt();

const props = defineProps({
  taskType: {
    type: String,
    required: true,
  },
});
const codeLanguageOption = ref([
  "Java",
  "Javascript",
  "Python",
  "Bash",
  "SQL",
  "C",
  "C++",
]);

const heading = ref("AI");
const buttonText = ref(props.taskType);

const code = ref(`//
//Starter Code
//
public class MyClass {
		public static void main(String args[]) {
			int x=10;`);

watch(
  () => props.taskType,
  () => {
    if (props.taskType !== undefined) {
      if (props.taskType === "complete") {
        buttonText.value = "Complete";
        heading.value = "AI - Code Completion";
        code.value = `//
//Starter Code
//
public class MyClass {
		public static void main(String args[]) {
			int x=10;`;
      }
      if (props.taskType === "translate") {
        buttonText.value = "Translate";
        heading.value = "AI - Code Translation";
      }
      if (props.taskType === "comment") {
        buttonText.value = "Comment";
        heading.value = "AI - Code Comment";
      }
      if (props.taskType === "optimize") {
        buttonText.value = "Optimize";
        heading.value = "AI - Code Optimization";
      }
      if (props.taskType === "debug") {
        buttonText.value = "Debug";
        heading.value = "AI - Code Debuging";
      }
      if (props.taskType === "generate") {
        buttonText.value = "Generate";
        heading.value = "AI - Code Generation";
      }
    }
  },
  { immediate: true }
);

watch(
  () => props.taskType,
  () => {
    if (props.taskType !== undefined) {
      if (props.taskType !== "complete") {
        code.value = `public class Fibonacci {

public static void main(String[] args) {
    int n = 10;
    int fibonacci = fibonacci(n);
    System.out.println("The " + n + "th Fibonacci number is " + fibonacci);
}

public static int fibonacci(int n) {
    if (n <= 1) {
        return n;
    } else {
        return fibonacci(n - 1) + fibonacci(n - 2);
    }
  }
}`;
      }
    }
  },
  { immediate: true }
);

const extensions = [javascript(), oneDark, EditorView.lineWrapping];

const language = ref("Java");

const isLoading = ref(false);
const apiCallPass = ref(false);

const refresh = () => {
  apiCallPass.value = false;
};
async function completeCode() {
  try {
    isLoading.value = true;
    let reqBody;
    if (props.taskType === "translate") {
      reqBody = JSON.stringify({
        prompt: code.value,
        task: props.taskType,
        language: language.value,
      });
    } else {
      reqBody = JSON.stringify({
        prompt: code.value,
        task: props.taskType,
      });
    }

    const response = await fetch("/engine/generate-code", {
      method: "POST",
      headers: {
        "Accept": "*/*",
        "Content-Type": "application/json",
      },
      body: reqBody,
    });

    if (response.ok) {
      let AIcode = await response.text();
      let print = JSON.parse(AIcode);

      if (props.taskType !== undefined) {
        if (props.taskType === "complete") {
          code.value += print;
          isLoading.value = false;

          return code.value;
        }
        if (props.taskType === "translate") {
          if (print.startsWith("```") && print.endsWith("```")) {
            print = print.slice(3, -3);
          }
          code.value = print;
          isLoading.value = false;

          return code.value;
        }
        if (props.taskType === "comment") {
          isLoading.value = false;
          code.value = print;
          return code.value;
        }
        if (props.taskType === "optimize") {
          code.value = print;
          isLoading.value = false;
          return code.value;
        }
        if (props.taskType === "debug") {
          isLoading.value = false;
          code.value = print;
          return code.value;
        }
      }
    }
    throw new Error("Something went wrong");
  } catch (error) {
    isLoading.value = false;
    apiCallPass.value = true;
    console.error("Error:", error);
  }
}
</script>

<template>
  <div class="">
    <div v-if="apiCallPass" class="grid place-items-center mt-20">
      <img
        class="w-[5%] rounded-full"
        src="../assets/maintenance.svg"
        alt="Maintenance"
      />
      <div>Our AI is under maintenance, please try again later!!</div>
      <div>
        <button
          type="button"
          class="py-3 px-4 m-4 gap-2 rounded-md border border-transparent font-semibold jdoodle-orange hover:bg-red-500 text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
          @click="refresh"
        >
          Retry
        </button>
      </div>
    </div>

    <div v-if="!apiCallPass" class="p-4 border rounded-md border-gray-200">
      <div class="wrapper">
        <div class="flex justify-start text-2xl pb-5">
          {{ heading }}
        </div>
      </div>

      <div class="">
        <codemirror
          v-model="code"
          placeholder="Code goes here..."
          :style="{ height: '400px', borderRadius: '5px' }"
          :autofocus="true"
          :indent-with-tab="true"
          :tab-size="2"
          :extensions="extensions"
        />
      </div>

      <div
        v-if="isLoading === false"
        class="flex flex-col md:flex-row justify-between"
      >
        <div>
          <div v-if="props.taskType === 'translate'">
            <label
              for="af-submit-app-category"
              class="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-gray-200"
            >
              Select Language
            </label>

            <select
              id="af-submit-app-category"
              v-model="language"
              class="py-2 px-3 pr-9 block w-full border-2 border-gray-200 shadow-sm rounded-lg text-sm focus:border-red-500 focus:ring-red-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
            >
              <option
                class="border border-gray-600"
                v-for="language in codeLanguageOption"
                :key="language"
                :value="language"
              >
                {{ language }}
              </option>
            </select>
          </div>
        </div>
        <button
          type="button"
          class="py-3 px-4 m-4 gap-2 rounded-md border border-transparent font-semibold jdoodle-orange hover:bg-red-500 text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
          @click="completeCode"
        >
          {{ buttonText }} Code
        </button>
      </div>
      <div v-if="isLoading === true" class="flex justify-center">
        <button
          disabled
          type="button"
          class="text-white jdoodle-orange hover:bg-red-500 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center m-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
        >
          <svg
            aria-hidden="true"
            role="status"
            class="inline w-4 h-4 mr-3 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
          Processing...
        </button>
      </div>
    </div>
  </div>
</template>
