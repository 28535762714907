<!-- @format -->

<script setup>
import { ref } from "vue";

const activeId = ref(null);

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits([
  "selectOption",
  "showIde",
  "showAssignment",
  "showFeedbackForm",
  "showCodeCompletion",
]);

const selectOption = (option) => {
  emits("selectOption", option);
  activeId.value = option;
};
const showCodeCompletion = (flag, task) => {
  emits("showCodeCompletion", flag, task);
};

const showAssignment = (flag) => {
  emits("showAssignment", flag);
};
const showIde = (flag) => {
  emits("showIde", flag);
};

const showFeedbackForm = (flag) => {
  emits("showFeedbackForm", flag);
};
</script>
<template>
  <div
    :class="[
      '  h-full pb-10 m-0 bg-white duration-300 transform translate-x-0  left-0 right-1  border-r border-gray-200 overflow-y-auto  lg:block  lg:bottom-0',
      props.isOpen
        ? 'w-72 px-2  ease-in opacity-100'
        : 'w-0 ease-out opacity-0',
    ]"
  >
    <nav class="p-6 w-full flex flex-col" data-hs-accordion-always-open>
      <ul class="pl-3 pt-2 space-y-3" data-hs-accordion-always-open>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 0 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(0);
                showIde(false);
              }
            "
          >
            Java Simple
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 1 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(1);
                showIde(false);
              }
            "
          >
            HTML Simple
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 2 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(2);
                showIde(false);
              }
            "
          >
            Php with Database
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 3 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(3);
                showIde(false);
              }
            "
          >
            Java DB multifile
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 4 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(4);
                showIde(false);
              }
            "
          >
            Java Empty Stage
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 5 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(5);
                showIde(false);
              }
            "
          >
            PHP HTML
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 6 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(6);
                showIde(false);
              }
            "
          >
            Embed plugin
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 7 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              {
                selectOption(7);
                showIde(false);
              }
            "
          >
            JD GUI Embed
          </a>
        </li>

        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 8 ? 'bg-gray-500 text-white' : ''"
            href="#"
            @click="
              showIde(true);
              activeId = 8;
            "
          >
            AI Code Generation
            <span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 9 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'complete');
              activeId = 9;
            "
          >
            AI Code Completion<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 10 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'optimize');
              activeId = 10;
            "
          >
            AI Code Optimize<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 11 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'comment');
              activeId = 11;
            "
          >
            AI Code Comment<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 12 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'translate');
              activeId = 12;
            "
          >
            AI Code Translate<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 13 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showCodeCompletion(true, 'debug');
              activeId = 13;
            "
          >
            AI Code Debug<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>
        <li>
          <a
            class="relative flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-200 text-sm text-slate-700 rounded-md hover:bg-gray-400"
            :class="activeId == 14 ? 'bg-gray-500 text-white' : ''"
            href="#"
            data-hs-overlay="#hs-assignment-modal"
            @click="
              showAssignment(true);
              activeId = 14;
            "
          >
            AI Question Generation<span
              class="jdoodle-orange py-1.2 px-1.5 rounded-md absolute right-2 top-2"
              >beta</span
            >
          </a>
        </li>

        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 bg-orange-400 text-sm text-white rounded-md hover:bg-orange-500"
            :class="
              (activeId == 15 ? 'jdoodle-orange text-white' : '',
              !props.isOpen ? 'hidden' : 'block')
            "
            href="#"
            @click="
              showFeedbackForm(true);
              activeId = 11;
            "
          >
            Feedback
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<style scoped>
.jdoodle-orange {
  background-color: #fd5200;
}
</style>
