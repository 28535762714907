<!-- @format -->

<template>
  <div class="container mx-auto px-auto">
    <div class="py-2 px-2.5 hidden md:block">
      <div
        class="flex justify-center text-center border-2 border-grey-700 p-5 rounded-lg text-sm bg-gray-200"
      >
        {{ props.desc }}
      </div>
    </div>
    <div class="h-full aspect-square">
      <iframe
        ref="iframe"
        frameborder="0"
        style="height: 650px; width: 100%; border: none; aspect-ratio: 4 / 1"
        :srcdoc="code"
        width="100%"
        height="100%"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
  codeFromBox: {
    type: String,
    required: true,
  },
  desc: {
    type: String,
    required: true,
  },
});

const desc = ref(props.desc);
const code = ref(props.code);

watch(
  () => props.codeFromBox,
  (newValue, oldValue) => {
    if ((oldValue = null)) {
      code.value = codeFromBox.value;
    }
    code.value = newValue;
  },
  { deep: true }
);
</script>

<style>
.preview {
  height: 85vh;
  width: 35vw;
}

iframe {
  border: none;
}
</style>
