<!-- @format -->

<script setup>
import "prismjs/themes/prism.css";
import { ref } from "vue";
import { Codemirror } from "vue-codemirror";
import { html } from "@codemirror/lang-html";
import { oneDark } from "@codemirror/theme-one-dark";
import { EditorView } from "codemirror";

const emits = defineEmits(["updateIframeContent"]);
const codeFromBox = ref(props.code);

const extensions = [html(), oneDark, EditorView.lineWrapping];
const props = defineProps({
  code: {
    type: String,
    required: true,
  },
});

const updateIframeContent = () => {
  emits("updateIframeContent", codeFromBox.value);
};
</script>

<template>
  <div class="">
    <div>
      <Codemirror
        v-model="codeFromBox"
        :model-value="props.code"
        placeholder="Code goes here..."
        :style="{
          height: '650px',
          borderRadius: '10px',
          overflow: 'hidden',
        }"
        :autofocus="true"
        :indent-with-tab="true"
        :tab-size="2"
        :extensions="extensions"
      />
    </div>

    <div
      class="bg-orange-400 my-8 flex justify-center text-white rounded-md py-2 px-2.5 self-center hover:bg-orange-500"
    >
      <button @click="updateIframeContent">Refresh plugin</button>
    </div>
  </div>
</template>

<style scoped></style>
