<!-- @format -->

<script setup>
import { ref } from "vue";
import MarkdownIt from "markdown-it";

// List of Options
const levelOption = ref([
  "First Year",
  "Second Year",
  "Third Year",
  "Fourth Year",
]);
const codeLanguageOption = ref([
  "Java",
  "Javascript",
  "Python",
  "Bash",
  "SQL",
  "C",
  "C++",
]);

const instructionLanguageOption = ref([
  "English",
  "Spanish",
  "French",
  "German",
  "Russian",
  "Dutch",
]);
const taskDifficultyOption = ref(["Easy", "Medium", "Hard"]);

// Indvidual Options
const courseTitle = ref("Data Structures 101");
const taskArea = ref("tree-search algorithms");
const level = ref("First Year");
const codeLanguage = ref("Java");
const testLanguage = ref("Java");
const instructionLanguage = ref("English");
const taskDifficulty = ref("Easy");

const markdown = new MarkdownIt();

const assignement = ref(null);
const isLoading = ref(false);

const apiCallPass = ref(false);

const refresh = () => {
  apiCallPass.value = false;
  assignement.value = null;
};

async function generateQuestion() {
  try {
    isLoading.value = true;

    const response = await fetch("/engine/generate-question", {
      method: "POST",
      headers: {
        "Accept": "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        level: level.value,
        courseTitle: courseTitle.value,
        taskArea: taskArea.value,
        codeLanguage: codeLanguage.value,
        testLanguage: testLanguage.value,
        instructionLanguage: instructionLanguage.value,
        taskDifficulty: taskDifficulty.value,
      }),
    });
    if (response.ok) {
      let markDownCode = await response.text();
      assignement.value = await markdown.render(markDownCode);
      isLoading.value = false;
      return assignement.value;
    }
    throw new Error("Something went wrong");
  } catch (error) {
    isLoading.value = false;
    apiCallPass.value = true;
    console.error("Error:", error);
  }
}
</script>
<style lang="css" src="./assignment.css"></style>

<template>
  <div class="flex justify-start text-2xl py-5">
    AI - Assignment Question Generation
  </div>
  <!-- Assignment -->
  <div v-if="assignement !== null" class="flex flex-col gap-2 w-full h-[80vh]">
    <div
      class="assignment_container border border-grey-700 p-5 rounded-lg text-sm overflow-x-auto overflow-y-scroll h-3/4"
      v-html="assignement"
    ></div>
    <div class="align-middle">
      <button
        type="button"
        class="py-3 px-4 m-x-4 gap-2 rounded-md border border-transparent font-semibold jdoodle-orange hover:bg-red-500 text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
        @click="refresh"
      >
        Regenerate
      </button>
    </div>
  </div>

  <!-- Loader -->
  <div v-else-if="isLoading === true" class="flex justify-center mt-64">
    <button
      disabled
      type="button"
      class="text-white jdoodle-orange hover:bg-red-500 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800 inline-flex items-center"
    >
      <svg
        aria-hidden="true"
        role="status"
        class="inline w-4 h-4 mr-3 text-white animate-spin"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"
        />
      </svg>
      Processing...
    </button>
  </div>

  <!-- Form -->
  <div v-else class="grid grid-cols-6 grid-flow-col min-w-4xl">
    <div
      v-if="!apiCallPass"
      class="col-span-5 col-start-1 border border-gray-300 rounded-md p-7"
    >
      <form>
        <div class="mb-5">
          Course Title
          <label for="hs-hero-name-2" class="block text-xs font-medium"
            ><span class="sr-only">Course Title</span></label
          >
          <input
            id="hs-hero-name-2"
            v-model="courseTitle"
            type="text"
            class="md:py-3 md:px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-orange-500 focus:ring-orange-500 p-2"
            placeholder="Course Title"
          />
        </div>

        <div class="mb-5">
          Task Area
          <label
            for="hs-hero-email-2"
            class="block text-sm font-medium dark:text-white"
            ><span class="sr-only">Task Area</span></label
          >
          <input
            id="hs-hero-email-2"
            v-model="taskArea"
            type="text"
            class="md:py-3 md:px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-orange-500 focus:ring-orange-500 p-2"
            placeholder="Task Area"
          />
        </div>

        <div class="space-y-2 mb-3">
          <label
            for="af-submit-app-category"
            class="inline-block text-sm font-medium text-gray-800 mt-1 dark:text-gray-200"
          >
            Level
          </label>

          <select
            id="af-submit-app-category"
            v-model="level"
            class="py-2 px-3 pr-9 block w-full border-2 border-gray-200 shadow-sm rounded-lg text-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option v-for="level in levelOption" :key="level" :value="level">
              {{ level }}
            </option>
          </select>
        </div>

        <div class="space-y-2 mb-3">
          <label
            for="af-submit-app-category"
            class="inline-block text-sm font-medium text-gray-800 mt-1 dark:text-gray-200"
          >
            Code Language
          </label>

          <select
            id="af-submit-app-category"
            v-model="codeLanguage"
            class="py-2 px-3 pr-9 block w-full border-2 border-gray-200 shadow-sm rounded-lg text-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option
              v-for="codeLanguage in codeLanguageOption"
              :key="codeLanguage.id"
              :value="codeLanguage"
            >
              {{ codeLanguage }}
            </option>
          </select>
        </div>

        <div class="space-y-2 mb-3">
          <label
            for="af-submit-app-category"
            class="inline-block text-sm font-medium text-gray-800 mt-1 dark:text-gray-200"
          >
            Test Language
          </label>

          <select
            id="af-submit-app-category"
            v-model="testLanguage"
            class="py-2 px-3 pr-9 block w-full border-2 border-gray-200 shadow-sm rounded-lg text-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option
              v-for="codeLanguage in codeLanguageOption"
              :key="codeLanguage.id"
              :value="codeLanguage"
            >
              {{ codeLanguage }}
            </option>
          </select>
        </div>

        <div class="space-y-2 mb-3">
          <label
            for="af-submit-app-category"
            class="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-gray-200"
          >
            Instruction Language
          </label>

          <select
            id="af-submit-app-category"
            v-model="instructionLanguage"
            class="py-2 px-3 pr-9 block w-full border-2 border-gray-200 shadow-sm rounded-lg text-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option
              v-for="instructionLanguage in instructionLanguageOption"
              :key="instructionLanguage.id"
              :value="instructionLanguage"
            >
              {{ instructionLanguage }}
            </option>
          </select>
        </div>

        <div class="space-y-2 mb-3">
          <label
            for="af-submit-app-category"
            class="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-gray-200"
          >
            Test Difficulty
          </label>

          <select
            id="af-submit-app-category"
            v-model="taskDifficulty"
            class="py-2 px-3 pr-9 block w-full border-2 border-gray-200 shadow-sm rounded-lg text-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option
              v-for="taskDifficulty in taskDifficultyOption"
              :key="taskDifficulty.id"
              :value="taskDifficulty"
            >
              {{ taskDifficulty }}
            </option>
          </select>
        </div>

        <div class="mt-5 flex justify-center gap-x-2">
          <button
            type="button"
            class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold jdoodle-orange hover:bg-red-500 text-white focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 transition-all text-sm"
            @click="generateQuestion"
          >
            Generate Assignment
          </button>
        </div>

        <!-- Display assignment -->
      </form>
    </div>
  </div>
  <div v-if="apiCallPass" class="grid place-items-center mt-20">
    <img
      class="w-[5%] rounded-full"
      src="../assets/maintenance.svg"
      alt="Maintenance"
    />
    <div>Our AI is under maintenance, please try again later!!</div>
    <div>
      <button
        type="button"
        class="py-3 px-4 m-4 gap-2 rounded-md border border-transparent font-semibold jdoodle-orange hover:bg-red-500 text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm"
        @click="refresh"
      >
        Retry
      </button>
    </div>
  </div>
</template>

<style></style>
